import React from 'react'
import Layout from '../components/layout'
import SVG from '../components/benefit-icons'
import Seo from '../components/seo'

const AboutUs = () => (
  <Layout>
  <Seo title='Brit Evcil Hayvan Mamaları Hakkında' desc='Brit Grubu tarihçesi ve Brit Veteriner Diyet Mamaları Ana Felsefesi'/>
    <section className="section section--full section--hypoallergenic">
      <div className="section__caption">
        <span id="dogs-hypoallergenic">Vafo: Önde gelen Evcİl Hayvan Maması Üretİcİsİ</span>
      </div>
      <div className="container">
        <div className="section__content">
        <div className="py-4">

        <div className="mb-4 mb-lg-6">
            <h1 className="h2 text-center">Hakkımızda</h1>
            <p className="lead text-center font-weight-bold">Vafo Grubu Yüksek Kaliteli Evcil Hayvan Mamaları, Ödülleri ve Takviyeleri Üreticisidir.</p>
        </div>

        <div className="row">
            <div className="col-12 col-lg-12 mb-4">
                <p>Şirket 1994 yılında kurulmuştur. 2017 yılında 140 Mio EURO ciroya ulaşarak Avrupa'nın lider üreticilerinden biri haline gelmiştir. </p>
                <p>Şirketin ana hedefi en yüksek kalite standardlarını karşılabilecek yüksek kaliteli ürünler üretmektir. En yüksek kaliteye odaklanmış olması sebebiyle, üretim tesisleri, geniş bir yelpazede super premium ürünler üretebilecek şekilde en son sistem teknoloji ile donatılmıştır. Taze et ekstruzyonu, laboratuvar, insan tüketimine uygun üretim tesisleri şirketin en son yatırımlarını oluşturmaktadır. </p>
                <p>Şirketin ana markası 1999 yılında doğmuştur. Kısa sürede 4 kıta, 70 ülkede önemli pazar hacmine ulaşmış, Avrupa Birliği, İskandinav ve Baltık ülkelerinde ciddi pazar payına sahiptir. Şirketin değerlerinde kök bulan sürekli gelişim ve en iyi bakım anlayışı doğrultusunda yeni veteriner diyet serisini sunar:</p>
                <p><strong>BRIT VETERİNER DİYET</strong></p>
                <p>Yıllar boyunca edinilen bilgi ve yüksek kaliteli evcil hayvan mamaları üretimi konusundaki tecrübeyi barındıran yeni veteriner diyet serisi ile Veterinerlerimize ve Evcil Hayvan ebeveynlerine sağlıklı bir alternatif sunmak amaçlanmaktadır.</p>
            </div>
            
        </div>
</div>
          <div className="py-4">
            <div className="mb-4 mb-lg-6">
              <h2 className="h3">Brit Veterİner Dİyet Serİsİ &ndash; ANA YAKLAŞIM</h2>
              <p>Brit Veteriner Diyet Mama serisi, sığır eti, süt ürünleri, buğday, mısır ve soya gibi alerjen içerikler <strong>kullanılmadan</strong> üretilmektedir. Protein kaynakları besinsel amaca uygun olarak <strong>dikkatle</strong> seçilmiştir. Diyetin fonksiyonel ve besinsel yönünü desteklemek amacı ile probiyotik, bioaktif vitaminler, DHA ve EPA yönünden zengin içerikler ile güçlendirilmiştir. Ürünler veteriner klinikleri tarafından bilimsel metodlar ile test edilmiştir.</p>
                            <p>Mamalarımızın ortak faydaları:</p>
            </div>
            <div className="row mb-4 mb-lg-6">
              <div className="col-12 col-md-6 mb-4 mb-lg-6">
                <div className="row no-gutters">
                  <div className="col-auto mr-1">
                  <SVG width="96" height="96" viewBox="0 0 64 64" className="benefits__icon" name='grainfree'/>
                  </div>
                  <div className="col ml-1">
                    <h4 className="h4 mb-1">Tahılsız</h4>
                    <p>Tahıllar sıkça "boş kaloriler" olarak da anılırlar. Tahıl yönünden zengin gıda rejimleri, obezitenin ana sebepleri olarak görülmektedir. Bunun için Veteriner Diyet serimiz tahılsızdır, çok rahat tolere edilir ve obeziteyi önler. </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 mb-4 mb-lg-6">
                <div className="row no-gutters">
                  <div className="col-auto mr-1">
                  <SVG width="96" height="96" viewBox="0 0 64 64" className="benefits__icon" name='nochicken'/>
                  </div>
                  <div className="col ml-1">
                    <h4 className="h4 mb-1">Tavuk proteİnİ ve yaĞı İÇermez</h4>
                    <p>Ticari mamaların bir çoğunda tavuk bulunmaktadır. Son 10 yılda tavuk alerjileri artış göstermektedir. Bundan dolayı köpek formüllerimizde tavuk bulunmamaktadır.</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 mb-4 mb-lg-6">
                <div className="row no-gutters">
                  <div className="col-auto mr-1">
                  <SVG width="96" height="96" viewBox="0 0 64 64" className="benefits__icon" name='probiotic'/>
                  </div>
                  <div className="col ml-1">
                    <h4 className="h4 mb-1">PrOBİYOTİKLER</h4>
                    <p>Bağırsak florasının ve sindirimin düzenlenmesine katkıda bulunarak genel sağlık durumunu ve hastalık sonrası iyileşme sürecini destekleyen doğal katkılardır.</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 mb-4 mb-lg-6">
                <div className="row no-gutters">
                  <div className="col-auto mr-1">
                  <SVG width="96" height="96" viewBox="0 0 64 64" className="benefits__icon" name='omega3'/>
                  </div>
                  <div className="col ml-1">
                    <h4 className="h4 mb-1">Omega-3, DHA, EPA</h4>
                    <p>Yüksek Omega-3, DHA ve EPA içeren gıdalar sayesinde anti-enflammatuvar etki sağlar ve derinin bariyer fonksiyonunu canlandırır.</p>
                  </div>
                </div>
              </div>
            </div>
            
            
          </div>
        </div>
      </div>
    
    
    </section>
  </Layout>
)
export default AboutUs